<template>
  <div>
    <el-dialog title="Сканирование" width="90%" :visible.sync="dialogVisible" :before-close="handleClose"
      append-to-body>
      <el-row :gutter="20" v-loading="loading">
        <el-col :span="6">
          <div>
            <el-header>Настройки сканирования</el-header>
            <el-divider></el-divider>

            <el-main>Выберите сканер:
              <el-select v-model="scanListValue">
                <el-option v-for="item in scanListOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-main>
            <el-main>
              <el-checkbox v-model="streamChecked">Потоковое сканирование</el-checkbox>
            </el-main>
            <el-main>Выберите цветовой профиль:
              <el-select v-model="colorSchemeValue" placeholder="Выберите профиль">
                <el-option v-for="item in colorSchemeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-main>
          </div>
        </el-col>
        <el-col :span="18">
          <div>
            <el-header>Предпросмотр</el-header>
            <el-divider></el-divider>
            <el-main style="height: calc(100vh - 480px); overflow-y: auto">
              <div>
                <el-image v-for="(item, index) in previewImage" style="width: 25%; margin-right: 15px" :key="index"
                  :src="'data:image/png;base64, ' + item" lazy>
                </el-image>
              </div>
            </el-main>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <span style="margin-right: 10px">Отсканировано страниц: {{ pageCount }}
        </span>
        <el-button type="primary" @click="scan()" icon="el-icon-picture-outline">Сканировать</el-button>
        <el-button type="primary" @click="createPDF()" icon="el-icon-download">Загрузить PDF</el-button>
        <el-button @click="dialogVisible = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Внимание!" :visible.sync="showError" :before-close="handleModalClose" append-to-body>
      Программа сканирования не запущена.
      <br />Скачайте и запустите программу
      <a target="_blank" href="/AccentScanner/AccentScanner.rar">Accent Scanner</a>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: true,
      colorSchemeOptions: [
        {
          value: 'bw',
          label: 'Чёрно-белый'
        },
        {
          value: 'gray',
          label: 'Серый'
        },
        {
          value: 'color',
          label: 'Цветной'
        }
      ],
      colorSchemeValue: 'bw',
      scanListOptions: [],
      scanListValue: 0,
      streamChecked: false,
      previewImage: [],
      pageCount: 0,
      guid: '',
      port: 9000,
      loading: true,
      error: false,
      showError: false,
      defaultScanner: 0
    }
  },
  methods: {
    initiateScan () {
      let me = this
      let socket = `ws://127.0.0.1:${this.port}`
      this.socket = new WebSocket(socket)

      this.socket.onerror = function () {
        me.showError = true
      }

      this.socket.onopen = function () {
        me.send({ action: 'getGuid', guid: this.guid }, function (json) {
          if (json.success) {
            me.guid = json.data
            me.loading = false
          }
        })
      }

      this.waitForSocketConnection(this.socket, function () {
        me.send({ action: 'getScannersList' }, function (json) {
          if (json.success) {
            me.scanListOptions = json.data.map((item, i) => {
              return { value: i, label: item }
            })
          } else {
            me.showError = true
          }
        })
      })
    },

    waitForSocketConnection (socket, callback, i = 0) {
      let me = this
      if (i === 10) return
      setTimeout(function () {
        if (socket.readyState === 1) {
          if (typeof callback === 'function') callback()
        } else {
          me.waitForSocketConnection(socket, callback, ++i)
        }
      }, 500)
    },

    scan () {
      let me = this
      me.send(
        { action: 'setScanner', scanner: me.scanListValue },
        function (json) {
          let settings = {
            SettingPixelType: me.colorSchemeValue,
            multi: me.streamChecked,
            guid: me.guid
          }
          me.send(
            Object.assign({ action: 'getScans' }, settings),
            function (json) {
              if (json.success) {
                json.data.forEach(function (item) {
                  me.previewImage.push(item)
                  me.pageCount++
                })
              }
            }
          )
        }
      )
    },

    createPDF () {
      let me = this
      me.send({ action: 'getPdfFile' }, function (json) {
        if (json.success) {
          let fileName = me.guid
          me.urltoFile(
            'data:application/pdf;base64,' + json.data,
            fileName + '.pdf',
            'application/pdf'
          ).then(function (file) {
            let uploadFile = {
              raw: file,
              uploaded: false,
              name: fileName + '.pdf'
            }
            me.$emit('savePdfFile', uploadFile)
            me.dialogVisible = false
          })
        } else {
          console.error(json.data)
        }
      })
    },

    async urltoFile (url, filename, mimeType) {
      const res = await fetch(url)
      const buf = await res.arrayBuffer()
      return new File([buf], filename, { type: mimeType })
    },

    send (json, callback) {
      this.socket.onmessage = function (data) {
        let json = {}
        try {
          json = JSON.parse(data.data)
        } catch (e) {
          json.success = false
          json.msg = 'error decode json:' + data.data
        }
        if (typeof callback === 'function') callback(json)
      }
      this.socket.send(
        JSON.stringify(Object.assign(json, { guid: this.guid }))
      )
    },

    handleClose (done) {
      this.$confirm('Вы уверены, что хотите закрыть окно сканирования?')
        .then((_) => {
          done()
        })
        .catch((_) => { })
    },

    handleModalClose () {
      this.dialogVisible = false
    }
  },
  watch: {
    dialogVisible (value) {
      if (!value) this.$emit('close')
    }
  },
  mounted () {
    this.initiateScan()
  }
}
</script>

<style scoped>

</style>
